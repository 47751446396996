import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { apis } from '../../../config/apiConfig'
import AsyncFetch from '../../../utilities/AsyncFetch'
import MakeDataConfigComponent from '../../../utilities/dataConfig'
import { refreshDetails, getMapHomeLocation } from '../../../utilities/user'
import * as utils from '../../../utilities/util'
import scss from './MapSelector.scss'
import ZoomToPoint from '../../../components/ZoomToPoint/ZoomToPoint'

const MapSelector = React.memo(() => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  // const viewport = useSelector(state => state.viewport)
  const [mapList, setMapList] = useState([])
  const [loading, setLoading] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
  const [homeCoordinates, setHomeCoordinates] = useState(null)
  const [homeZoom, setHomeZoom] = useState(null)
  const [zoomToPointKey, setZoomToPointKey] = useState(null)

  const account = utils.objectKeyVal(user.accountID, user.accounts, 'id')

  const getMapList = () => {
    const acc = utils.objectKeyVal(user.accountID, user.accounts, 'id')
    const mapListNew = acc.maps
    setMapList(mapListNew)
  }

  const getHomeLocation = () => {
    const location = getMapHomeLocation(user)
    const coordsArray = [location.y, location.x]
    const zoom = parseFloat(location.z)
    setHomeCoordinates(coordsArray)
    setHomeZoom(zoom)
  }

  const goHome = () => {
    let newKey = zoomToPointKey
    if (!newKey) newKey = 1
    newKey += 1
    setZoomToPointKey(newKey)
  }

  const buildFetchParams = e => {
    const mapID = e.target.value

    const method = 'POST'
    const url = apis.apiDatabase.uri + 'user/update'
    const body = {
      mapID,
    }

    setFetchObjects([{ url, method, body }])
    setLoading(true)
  }

  const fetchFinished = result => {
    refreshDetails(user, newUser => {
      dispatch({ type: 'REFRESH', payload: newUser })
      setMountMakeDataConfig(true)
    })

    setFetchObjects(null)
  }

  const updateMap = e => {
    buildFetchParams(e)
  }

  const makeDataConfigFinished = dataConfig => {
    setMountMakeDataConfig(false)
    setLoading(false)
    if (homeCoordinates) goHome()
    setLoading(false)
  }

  useEffect(() => {
    getMapList()
    getHomeLocation()
  }, [user])

  const mapSelectJSX = useMemo(
    () =>
      mapList.length > 0 ? (
        <div className={scss.mapSelectorSubhead}>
          <label className={scss.mapSelectorLabel}>Map</label>
          <div className={scss.mapSelector}>
            <select
              key={'map_selector_' + user.accountID + '_' + user.mapID}
              value={user.mapID ? user.mapID : ''}
              onChange={updateMap}
            >
              <option key='default' value='default'>
                Map
              </option>
              {mapList.map(map => (
                <option key={map.id} value={map.id}>
                  {map.name}
                </option>
              ))}
            </select>
            <FontAwesomeIcon
              icon='chevron-down'
              size='1x'
              pull='right'
              className={scss.mapSelectorIcon}
            />
          </div>
        </div>
      ) : (
        <div className={scss.loadingDiv}>
          You are not a part of this map.
          <br />
          Please add yourself to this map via the{' '}
          <Link to='/maps' style={{ color: '#337ab7' }}>
            Maps
          </Link>{' '}
          page.
        </div>
      ),
    [mapList, updateMap, user.accountID, user.mapID]
  )

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      {mountMakeDataConfig && (
        <MakeDataConfigComponent
          onFinish={makeDataConfigFinished}
          updateStyle={false}
          resetStyle
        />
      )}
      {zoomToPointKey && (
        <ZoomToPoint
          key={zoomToPointKey}
          // viewport={viewport}
          coordinates={homeCoordinates}
          zoom={homeZoom}
        />
      )}
      <div className={scss.mapSelectorContainer}>
        <div className={scss.mapSelectorHeader}>
          <span className={scss.mapSelectorAccount}>{account.name}</span>
        </div>
        {loading ? (
          <div className={scss.mapSelectorSubhead}>
            <label className={scss.mapSelectorLabel}>Map</label>
            <div className={scss.loadingDiv}>Loading...</div>
          </div>
        ) : (
          mapSelectJSX
        )}
      </div>
    </>
  )
})

export default MapSelector
